<template>
  <div class="background">
    <!-- 會員登入 -->
    <div class="login" v-if="page === '1'">
      <h2><mu-icon value="account_circle" style="margin-right: 10px; transform: translateY(4px);"></mu-icon>會員登入</h2>
      <hr style="width: 90%; border: 1px solid #eee; margin: 0 auto;"/>
      <div style="padding: 30px 50px;">
        <div style="text-align: left;">電子郵件或電話</div>
        <mu-text-field style="width: 100%;" v-model.trim="account"></mu-text-field>
        <div style="text-align: left; margin-top: 15px;">密碼</div>
        <mu-text-field style="width: 100%;" v-model.trim="password" :action-icon="visibility ? 'visibility_off' : 'visibility'" :action-click="() => (visibility = !visibility)" :type="visibility ? 'text' : 'password'"></mu-text-field>
        <div style="text-align: right; color: #1e7ad6; cursor: pointer;" @click="forgetPass">忘記密碼？</div>
        <div style="color: #f24;" v-if="showAlert">{{ alertMsg }}</div>
      </div>
      <div style="position: relative; padding: 0 20px;">
        <el-button @click="login" type="primary" style="width: 100%; bottom: 0;">登入</el-button>
      </div>
      <div style="padding: 20px;">
        還不是會員嗎？<span style="color: #1e7ad6; cursor: pointer;" @click="page = '2'">前往註冊</span>
      </div>
    </div>
    <!-- 會員註冊 -->
    <div class="login" v-if="page === '2'">
      <h2><mu-icon value="account_circle" style="margin-right: 10px; transform: translateY(4px);"></mu-icon>註冊會員</h2>
      <hr style="width: 90%; border: 1px solid #eee; margin: 0 auto;"/>
      <div style="padding: 30px 50px;">
        <!-- 名稱 -->
        <div style="text-align: left;">名稱</div>
        <mu-text-field :error-text="isName" v-model.trim="name" style="width: 100%;" placeholder="例: 王小明"></mu-text-field>
        <!-- 電話 -->
        <div style="text-align: left; margin-top: 15px;">電話</div>
        <mu-text-field :error-text="isPhone" v-model.trim="phone" style="width: 100%;" placeholder="例: 0912345678"></mu-text-field>
        <!-- Email -->
        <div style="text-align: left; margin-top: 15px;">Email</div>
        <mu-text-field :error-text="isEmail" v-model.trim="email" style="width: 100%;" placeholder="例: 12345@gmail.com"></mu-text-field>
        <!-- Pass -->
        <div style="margin-top: 25px;">
          <el-button type="primary" style="width: 100%; bottom: 0;" @click="checkPhone">電話驗證</el-button>
        </div>
        <div style="padding: 20px 20px 0 20px;">
          已經是會員？<span style="color: #1e7ad6; cursor: pointer;" @click="page = '1'">前往登入</span>
        </div>
      </div>
    </div>
    <!-- 電話驗證 -->
    <div class="login" v-if="page === '3'">
      <h2><mu-icon value="stay_primary_portrait" style="margin-right: 10px; transform: translateY(4px);"></mu-icon>驗證手機號碼</h2>
      <hr style="width: 90%; border: 1px solid #eee; margin: 0 auto;"/>
      <div style="text-align: left; padding: 20px; font-size: 15px;">
        已將驗證碼傳送至 +886{{ phone.substr(1, 10) }}
        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="15">
            <mu-text-field :error-text="isCode" v-model.trim="inputVer" style="width: 100%;" placeholder="請輸入 6 位數字驗證碼"></mu-text-field>
          </el-col>
          <el-col :span="9">
            <mu-button color="info" :disabled="count !== 0" @click="checkPhone">重新發送 {{ count === 0 ? '' : count }}</mu-button>
          </el-col>
        </el-row>
        <div style="margin-top: 25px;">
          <el-button type="primary" style="width: 100%; bottom: 0;" @click="checkCode" :disabled="!inputVer">下一步</el-button>
        </div>
        <div style="padding: 50px 20px 0 20px; text-align: center;">
          已經是會員？<span style="color: #1e7ad6; cursor: pointer;" @click="page = '1'">前往登入</span>
        </div>
      </div>
    </div>
    <!-- 電話驗證 -->
    <div class="login" v-if="page === '4'">
      <h2><mu-icon value="lock" style="margin-right: 10px; transform: translateY(4px);"></mu-icon>設定密碼</h2>
      <hr style="width: 90%; border: 1px solid #eee; margin: 0 auto;"/>
      <div style="padding: 30px 50px;">
        <!-- 密碼 -->
        <div style="text-align: left; margin-top: 15px;">密碼</div>
        <mu-text-field :error-text="isPass" v-model="regisPass" style="width: 100%;" placeholder="請輸入六位數以上英數組合"></mu-text-field>
        <!-- 確認密碼 -->
        <div style="text-align: left; margin-top: 15px;">確認密碼</div>
        <mu-text-field :error-text="isComfirmPass" v-model="comfirmPass" style="width: 100%;" placeholder="請再次輸入密碼"></mu-text-field>
        <!-- Pass -->
        <div style="margin-top: 25px;">
          <el-button type="primary" style="width: 100%; bottom: 0;" @click="finalCheck">註冊</el-button>
        </div>
        <!-- 連結 -->
        <div style="padding: 30px 20px 0 20px; text-align: center;">
          已經是會員？<span style="color: #1e7ad6; cursor: pointer;" @click="page = '1'">前往登入</span>
        </div>
      </div>
    </div>
    <!-- 重設密碼 -->
    <mu-dialog :open.sync="resetDia" width="450" title="忘記密碼">
      <!-- 電話驗證 -->
      <div style="margin-bottom: 20px;">
        <div v-if="active === 0">
          <el-input placeholder="請輸入註冊時的電話號碼" v-model="phone">
            <el-button style="margin: 0 !important;" slot="append" :disabled="count !== 0" @click="sendVer" :loading="count !== 0">發送驗證碼</el-button>
          </el-input>
          <div v-if="errorText" style="color: #f24; margin-top: 5px;">{{ errorText }}</div>
        </div>
        <div v-if="active === 1">
          <div style="margin-bottom: 10px;">已將驗證碼發送至 +886{{ phone.substr(1, 10) }}</div>
          <el-input v-model="inputVer" placeholder="請輸入驗證碼"></el-input>
          <div v-if="errorText" style="color: #f24;">{{ errorText }}</div>
        </div>
      </div>
      <!-- 按鈕 -->
      <mu-button v-if="active === 1 && resetType === 'phone'" slot="actions" flat color="primary" @click="sendVer" :disabled="count !== 0">重新發送 {{ count === 0 ? '' : count }}</mu-button>
      <mu-button v-if="active === 1 && resetType === 'phone'" slot="actions" color="primary" @click="checkVerCode">確認</mu-button>
    </mu-dialog>
    <!-- 註冊成功 -->
    <mu-dialog :open="successDia" width="450">
      <div style="margin-bottom: 20px;">註冊成功</div>
      <mu-button slot="actions" color="primary" @click="page = '1'; successDia = false">前往登入</mu-button>
    </mu-dialog>
    <!-- 註冊失敗 -->
    <mu-dialog :open="errorDia" width="450">
      <div style="margin-bottom: 20px;">{{ errorMessage }}</div>
      <mu-button slot="actions" color="primary" @click="page = '2'; errorDia = false">返回</mu-button>
    </mu-dialog>
    <div style="position: fixed; bottom: 10px; right: 10px; text-align: right;">
      <img src="logo_white.png" width="120"/>
      <div style="padding-left: 8px; font-size: 13px; color: #fff;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  name: 'landingPage',
  data() {
    return {
      page: '1',
      // 註冊資訊
      name: '',
      phone: '',
      email: '',
      uid: '',
      regisPass: '',
      comfirmPass: '',
      // 錯誤提醒
      isPass: '',
      isComfirmPass: '',
      isPhone: '',
      isName: '',
      isEmail: '',
      account: '',
      password: '',
      alertMsg: '',
      showAlert: false,
      visibility: false,
      successDia: false,
      errorMessage: '',
      errorDia: false,
      count: 0,
      active: 0,
      inputVer: '',
      code: '',
      isCode: '',
      resetDia: false,
      emailSent: false,
      alertText: '',
      resetTypes: [
        { value: 'phone', label: '電話驗證' },
        { value: 'email', label: 'Email 驗證' }
      ],
      resetType: 'phone',
      errorText: ''
    };
  },
  mounted() {
    try {
      if (this.parseCookie().username) {
        this.account = this.parseCookie().username;
      }
      if (localStorage.getItem('uid')) {
        this.$router.push('/firstPage');
      }
    }
    catch(e) {
      console.log(e);
    }
  },
  methods: {
    ...emc.mapActions({
      userLogin: 'userLogin',
      register: 'register',
      sendSMS: 'sendSMS',
      getUserByEmail: 'getUserByEmail'
    }),
    parseCookie() {
      var cookieObj = {};
      var cookieAry = document.cookie.split(';');
      var cookie;
      
      for (var i=0, l=cookieAry.length; i<l; ++i) {
          cookie = cookieAry[i].trim();
          cookie = cookie.split('=');
          cookieObj[cookie[0]] = cookie[1];
      }
      
      return cookieObj;
    },
    countDown() {
      this.count = 60;
      setInterval(() => {
        if (this.count > 0) {
          this.count -= 1
        }
      }, 1000)
    },
    sendVer() {
      this.errorText = '';
      this.countDown();
      const random = new Array('0','1','2','3','4','5','6','7','8','9');
      let code = '';
      for (let e = 0; e < 6; e += 1) {
        const index = Math.floor(Math.random() * 10);
        code += random[index];
      }
      this.code = code;
      this.getUserByEmail({ phone: this.phone }).then((res) => {
        if (res.uid) {
          const postData = {
            phone: this.phone,
            message: `你的驗證碼為: ${code} (Powered by EILIS)`
          };
          this.sendSMS(postData);
          this.uid = res.uid;
          this.active = 1;
        }
        else {
          this.errorText = '此電話尚未註冊';
        }
      })
    },
    forgetPass() {
      this.resetDia = true;
    },
    checkVerCode() {
      if (this.inputVer) {
        if (this.inputVer === this.code) {
          this.errorText = '';
          this.$router.push(`/resetPass/${this.uid}`);
        }
        else {
          this.errorText = '驗證碼輸入錯誤';
        }
      }
      else {
        this.errorText = '請輸入6位數字驗證碼';
      }
    },
    checkCode() {
      if (this.inputVer === this.code) {
        this.isCode = '';
        this.page = '4';
      }
      else {
        this.isCode = '輸入的驗證碼不符合';
      }
    },
    finalCheck() {
      if (this.regisPass.length >= 6) {
        this.isPass = '';
        if (this.regisPass === this.comfirmPass) {
          // 20201029
          const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
          this.register({ phone: this.phone, UserName: this.name, Email: this.email, Password: this.regisPass })
            .then((res) => {
              loading.close();
              if (res.data.Message === '註冊成功') {
                this.successDia = true;
              }
              else {
                this.errorMessage = res.data.Message;
                this.errorDia = true;
              }
            })
        }
        else {
          this.isComfirmPass = '密碼不符合';
        }
      }
      else {
        this.isPass = '密碼長度至少要 6 碼';
      }
    },
    checkPhone() {
      if (this.name && this.phone && this.email && this.phone.length === 10) {
        const random = new Array('0','1','2','3','4','5','6','7','8','9');
        let code = '';
        for (let e = 0; e < 6; e += 1) {
          const index = Math.floor(Math.random() * 10);
          code += random[index];
        }
        this.code = code;
        this.countDown();
        const postData = {
          phone: this.phone,
          message: `你的驗證碼為: ${code} (Powered by EILIS)`
        };
        this.sendSMS(postData);
        this.page = '3';
      }
      else {
        if (!this.name)      { this.isName     = '姓名為必填' }
        if (!this.phone)     { this.isPhone    = '電話為必填' }
        if (!this.email)     { this.isEmail    = 'Email 為必填' }
        if (this.phone && this.phone.length !== 10) { this.isPhone = '電話格式有誤' }
        if (this.name)       { this.isName     = '' }
        if (this.phone && this.phone.length === 10) { this.isPhone = '' }
        if (this.email)      { this.isEmail    = '' }
      }
    },
    login() {
      this.showAlert = false;
      if (this.password && this.account) {
        const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
        let postData = {};
        if (this.account.indexOf('@') !== -1) {
          postData = { email: this.account, password: this.password };
        }
        else {
          postData = { phone: this.account, password: this.password };
        }
        this.userLogin(postData)
          .then((res) => {
            // 儲存 cookie
            document.cookie = `username=${this.account}; max-age=2592000; path=/`;
            loading.close();
            if (res.uid) {
              localStorage.setItem('uid', res.uid);
              this.$router.push('/firstPage');
            }
            else {
              this.showAlert = true;
              this.alertMsg = '帳號或密碼錯誤';
            }
          })
      }
      else {
        this.showAlert = true;
        this.alertMsg = '有資料漏填囉！';
      }
    }
  }
}
</script>

<style scoped>
  .background {
    background: 100% linear-gradient(to left, rgb(0,128,129),rgb(0,49,82));
    padding: 20px;
    min-height: 800px;
    height: 100vh;
  }
  .link {
    color: #0a82f2;
    text-decoration: underline;
    cursor: pointer;
  }
  .login {
    width: 90%;
    max-width: 450px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    top: 15vh;
    background-color: #fff;
    display: inline-block;
    padding: 10px 10px;
  }
</style>